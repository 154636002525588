import { graphql } from 'gatsby';
import * as React from 'react';
import { Container, Icon, Image } from 'semantic-ui-react';
import crabHands from '../assets/crab-hands.png';
import Layout from '../components/Layout';


interface Props {
  data: {
    site: {
      siteMetadata: {
        social: {
          github: string;
        }
      };
    };
    allMarkdownRemark: {
      edges: any;
    };
  };
}

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        social { github }
      }
    }
  }
`;

const Page = ({ data }: Props) => {
  const { siteMetadata } = data.site;
  const { social } = siteMetadata;

  return (
    <Layout>
      <Container textAlign="center" text>
        <Image centered src={crabHands} size='medium' circular />
        <h1>programmer, code janitor</h1>
        <a target="_blank" href={`https://github.com/${social.github}`} style={{ color: "black" }}>
          <Icon link size="big" name="github" />
        </a>
      </Container>
    </Layout>
  );
};

export default Page;
